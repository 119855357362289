<template>
    <div>
        <p>profile</p>
    </div>
</template>

<script>
export default {
    name : 'profile'
};
</script>

<style scoped>

</style>
